import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/code/src/components/layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Columns = makeShortcode("Columns");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Columns left="Hagedorn" mdxType="Columns">
      <h5>{`Wir erstellen genau die Softwarelösungen, die auf die Bedürfnisse unserer Kunden zugeschnitten sind.`}</h5>
      <p>{`Wir arbeiten dabei selbstverständlich plattformübergreifend und herstellerneutral: Sie entscheiden, mit welchem System Sie arbeiten wollen. In allen Phasen Ihres IT-Projektes können Sie auf unsere kompetente Unterstützung und methodische Arbeitsweise zählen - von der Analyse der aktuellen Situation über die Implementierung und Dokumentation bis hin zu anschließendem Service und Support.`}</p>
      <h2>{`Wir bieten:`}</h2>
      <ul>
        <li parentName="ul">{`Methodische Analyse der aktuellen Situation unter Berücksichtigung der Anforderungen aller involvierten Abteilungen`}</li>
        <li parentName="ul">{`Realistische Kostenkalkulation und detaillierte Zeitpläne für die Implementierung`}</li>
        <li parentName="ul">{`Erstellung von Feinkonzepten oder Projekt-Setup mit agilen Methoden`}</li>
        <li parentName="ul">{`Implementierung der abgestimmten Lösung, Durchführung strukturierter Tests und detaillierter Dokumentation`}</li>
        <li parentName="ul">{`Integration der erstellten Module in eine Testumgebung, bei Bedarf mit Real-Life-Tests und Einbindung operativer Mitarbeiter des Auftraggebers`}</li>
        <li parentName="ul">{`Produktionseinführung mit Datensicherung und -migration, auf Wunsch mit anschließenden Support-Verträgen`}</li>
      </ul>
    </Columns>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      